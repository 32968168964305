import React, { useState } from 'react';
import { submitSurvey } from '../api';
import './SurveyForm.css'; // Import CSS for styles

// Language Options
const languageOptions = {
    en: {
        title: "Please Share Your Insights to Help Us Improve",
        overallSatisfaction: "How satisfied are you with the overall experience at our seafood buffet?",
        priceSatisfaction: "How do you feel about the price of 459 THB per person for our seafood buffet?",
        seafoodFreshness: "How satisfied are you with the freshness of the seafood?",
        favoriteThing: "What do you like the most about our seafood buffet?",
        seafoodPreference: "Which of the following seafood options do you prefer the most?",
        menuPreference: "Would it be okay with you if we switched from a buffet to an A La Carte menu?",
        customerService: "How satisfied are you with the customer service at our restaurant?",
        menuSuggestions: "Is there any seafood or dish you would like to see added to our buffet?",
        diningTimePreference: "How do you feel about the unlimited dining time at our buffet?",
        improvementSuggestion: "What do you suggest for improvement at Kingkaew Seafood Buffet?",
        submit: "Submit",
        thankYou: "Thank you for your feedback!",
        placeholders: {
            favoriteThing: "Please share what you liked the most",
            menuSuggestions: "Please share any suggestions",
            improvementSuggestion: "Please share any suggestions for improvement"
        },
        options: {
            overallSatisfaction: ['Very Satisfied', 'Satisfied', 'Neutral', 'Unsatisfied', 'Very Unsatisfied'],
            priceSatisfaction: ['Excellent value for money', 'Good value', 'Fair', 'Too expensive', 'Other'],
            seafoodFreshness: ['Very Satisfied', 'Satisfied', 'Neutral', 'Unsatisfied', 'Very Unsatisfied'],
            menuPreference: ['Yes, I would prefer A La Carte', 'Yes, but I still like the buffet option', 'No, I prefer the buffet', 'Not sure, depends on the menu', 'Other'],
            customerService: ['Very Satisfied', 'Satisfied', 'Neutral', 'Unsatisfied', 'Very Unsatisfied'],
            diningTimePreference: ['Love it, no rush!', 'It\'s good, but could be limited to 2-3 hours', 'Doesn\'t matter to me', 'Other'],
            seafoodOptions: [
                '🦀 Fresh Blue Crab (Pot-caught)',
                '🦀 Star Crab (Pot-caught)',
                '🦐 Live Giant Freshwater Prawns',
                '🦪 Oysters (Jarred)',
                '🐙 Cuttlefish',
                '🦐 White Shrimp',
                '🦞 River Prawns',
                '🦑 Squid Roe (Pressed)',
                'Other'
            ]
        }
    },
    th: {
        title: "กรุณาแบ่งปันความคิดเห็นของคุณ เพื่อให้เราปรับปรุงบริการให้ดียิ่งขึ้น",
        overallSatisfaction: "ท่านพึงพอใจมากน้อยเพียงใดกับประสบการณ์ที่ได้รับจากบุฟเฟ่ต์อาหารทะเลของเรา?",
        priceSatisfaction: "ท่านคิดเห็นอย่างไรเกี่ยวกับราคา 459 บาทต่อคนสำหรับบุฟเฟ่ต์อาหารทะเลของเรา?",
        seafoodFreshness: "ท่านพึงพอใจมากน้อยเพียงใดกับความสดของอาหารทะเล?",
        favoriteThing: "สิ่งที่ท่านชอบมากที่สุดเกี่ยวกับบุฟเฟ่ต์อาหารทะเลของเราคืออะไร?",
        seafoodPreference: "ท่านชอบอาหารทะเลตัวไหนมากที่สุด?",
        menuPreference: "หากเราจะเปลี่ยนจากบุฟเฟ่ต์เป็นเมนู A La Carte ท่านเห็นว่าเป็นอย่างไร?",
        customerService: "ท่านพึงพอใจมากน้อยเพียงใดกับการบริการของพนักงาน?",
        menuSuggestions: "มีอาหารทะเลหรือเมนูใดที่ท่านอยากให้เพิ่มเข้ามาหรือไม่?",
        diningTimePreference: "ท่านรู้สึกอย่างไรเกี่ยวกับการจำกัดเวลาการทานบุฟเฟ่ต์?",
        improvementSuggestion: "ท่านมีข้อเสนอแนะใดๆ สำหรับการปรับปรุงบุฟเฟ่ต์อาหารทะเลของเรา?",
        submit: "ส่ง",
        thankYou: "ขอบคุณสำหรับคำติชมของคุณ!",
        placeholders: {
            favoriteThing: "กรุณาแชร์สิ่งที่ท่านชอบมากที่สุด",
            menuSuggestions: "กรุณาแชร์ข้อเสนอแนะ",
            improvementSuggestion: "กรุณาแชร์ข้อเสนอแนะสำหรับการปรับปรุง"
        },
        options: {
            overallSatisfaction: ['พึงพอใจมาก', 'พึงพอใจ', 'เฉยๆ', 'ไม่พึงพอใจ', 'ไม่พึงพอใจมาก'],
            priceSatisfaction: ['คุ้มค่ามาก', 'คุ้มค่า', 'พอใช้', 'แพงเกินไป', 'อื่นๆ'],
            seafoodFreshness: ['พึงพอใจมาก', 'พึงพอใจ', 'เฉยๆ', 'ไม่พึงพอใจ', 'ไม่พึงพอใจมาก'],
            menuPreference: ['ใช่, ฉันชอบเมนู A La Carte มากกว่า', 'ใช่, แต่ยังคงชอบบุฟเฟ่ต์อยู่', 'ไม่, ฉันชอบบุฟเฟ่ต์', 'ยังไม่แน่ใจ, ขึ้นอยู่กับเมนู', 'อื่นๆ'],
            customerService: ['พึงพอใจมาก', 'พึงพอใจ', 'เฉยๆ', 'ไม่พึงพอใจ', 'ไม่พึงพอใจมาก'],
            diningTimePreference: ['ชอบมาก, ไม่เร่งรีบ!', 'ดี, แต่สามารถจำกัดเวลา 2-3 ชั่วโมง', 'ไม่สำคัญสำหรับฉัน', 'อื่นๆ'],
            seafoodOptions: [
                '🦀 ปูม้า สดๆ อวนลอย',
                '🦀 ปูดาว อวนลอย',
                '🦐 กุ้งก้ามกรามเป็นๆ',
                '🦪 หอยนางรม (แบบกระปุก)',
                '🐙 หมึกกระดอง',
                '🦐 กุ้งขาว',
                '🦞 กุ้งแม่น้ำ',
                '🦑 ไข่หมึกอัดแท่ง',
                'อื่นๆ'
            ]
        }
    },
    cn: {
        title: "请分享您的意见，帮助我们改进服务",
        overallSatisfaction: "您对我们海鲜自助餐的整体体验满意度如何?",
        priceSatisfaction: "您如何看待每人459泰铢的海鲜自助餐价格?",
        seafoodFreshness: "您对海鲜的新鲜度满意吗?",
        favoriteThing: "您最喜欢我们的海鲜自助餐的什么?",
        seafoodPreference: "您最喜欢的海鲜选项是哪一个?",
        menuPreference: "如果我们从自助餐切换到单点菜单，您觉得可以吗?",
        customerService: "您对我们餐厅的客户服务满意吗?",
        menuSuggestions: "您希望我们增加哪种海鲜或菜品?",
        diningTimePreference: "您对我们自助餐无限用餐时间有何看法?",
        improvementSuggestion: "您对Kingkaew海鲜自助餐的改进有何建议?",
        submit: "提交",
        thankYou: "感谢您的反馈！",
        placeholders: {
            favoriteThing: "请分享您最喜欢的部分",
            menuSuggestions: "请分享您的建议",
            improvementSuggestion: "请分享您的改进建议"
        },
        options: {
            overallSatisfaction: ['非常满意', '满意', '一般', '不满意', '非常不满意'],
            priceSatisfaction: ['物有所值', '价格合理', '一般', '太贵', '其他'],
            seafoodFreshness: ['非常满意', '满意', '一般', '不满意', '非常不满意'],
            menuPreference: ['是的, 我更喜欢单点菜单', '是的, 但我仍然喜欢自助餐选项', '不, 我喜欢自助餐', '不确定, 取决于菜单', '其他'],
            customerService: ['非常满意', '满意', '一般', '不满意', '非常不满意'],
            diningTimePreference: ['非常好, 不着急!', '很好, 但可以限制为2-3小时', '无所谓', '其他'],
            seafoodOptions: [
                '🦀 新鲜蓝蟹（捕捞）',
                '🦀 星蟹（捕捞）',
                '🦐 活大头虾',
                '🦪 牡蛎（罐装）',
                '🐙 墨鱼',
                '🦐 白虾',
                '🦞 河虾',
                '🦑 墨鱼卵（压制）',
                '其他'
            ]
        }
    }
};

const SurveyForm = () => {
    const [language, setLanguage] = useState('en'); // Default to English
    const [submitted, setSubmitted] = useState(false);

    // States for the survey answers
    const [overallSatisfaction, setOverallSatisfaction] = useState('');
    const [priceSatisfaction, setPriceSatisfaction] = useState('');
    const [priceOther, setPriceOther] = useState('');
    const [seafoodFreshness, setSeafoodFreshness] = useState('');
    const [favoriteThing, setFavoriteThing] = useState('');
    const [seafoodPreference, setSeafoodPreference] = useState('');
    const [seafoodOther, setSeafoodOther] = useState('');
    const [menuPreference, setMenuPreference] = useState('');
    const [customerService, setCustomerService] = useState('');
    const [menuSuggestions, setMenuSuggestions] = useState('');
    const [diningTimePreference, setDiningTimePreference] = useState('');
    const [diningTimeOther, setDiningTimeOther] = useState('');
    const [improvementSuggestion, setImprovementSuggestion] = useState('');

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            overall_satisfaction: overallSatisfaction,
            price_satisfaction: priceSatisfaction === 'Other' ? priceOther : priceSatisfaction,
            seafood_freshness: seafoodFreshness,
            favorite_thing: favoriteThing,
            seafood_preference: seafoodPreference === 'Other' ? seafoodOther : seafoodPreference,
            menu_preference: menuPreference,
            customer_service: customerService,
            menu_suggestions: menuSuggestions,
            dining_time_preference: diningTimePreference === 'Other' ? diningTimeOther : diningTimePreference,
            improvement_suggestion: improvementSuggestion,
        };

        await submitSurvey(formData);
        setSubmitted(true);
    };

    return (
        <div>
            {/* Language Switcher */}
            <div className="language-switcher">
                <button onClick={() => setLanguage('en')}>English</button>
                <button onClick={() => setLanguage('th')}>ไทย</button>
                <button onClick={() => setLanguage('cn')}>中文</button>
            </div>

            {submitted ? (
                <div className="feedback-message">
                    <i className="fas fa-check-circle"></i> {/* Optional FontAwesome icon */}
                    {languageOptions[language].thankYou}
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2>{languageOptions[language].title}</h2>

                    {/* Question 1: Overall Satisfaction */}
                    <div className="question-block">
                        <label>{languageOptions[language].overallSatisfaction}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.overallSatisfaction.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={overallSatisfaction === option}
                                        onChange={() => setOverallSatisfaction(option)}
                                    /> {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Question 2: Price Satisfaction */}
                    <div className="question-block">
                        <label>{languageOptions[language].priceSatisfaction}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.priceSatisfaction.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={priceSatisfaction === option}
                                        onChange={() => setPriceSatisfaction(option)}
                                    /> {option}
                                </label>
                            ))}
                            {priceSatisfaction === 'Other' && (
                                <input
                                    type="text"
                                    value={priceOther}
                                    onChange={(e) => setPriceOther(e.target.value)}
                                    placeholder={languageOptions[language].placeholders.favoriteThing}
                                />
                            )}
                        </div>
                    </div>

                    {/* Question 3: Seafood Freshness */}
                    <div className="question-block">
                        <label>{languageOptions[language].seafoodFreshness}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.seafoodFreshness.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={seafoodFreshness === option}
                                        onChange={() => setSeafoodFreshness(option)}
                                    /> {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Question 4: Favorite Thing */}
                    <div className="question-block">
                        <label>{languageOptions[language].favoriteThing}</label>
                        <textarea
                            value={favoriteThing}
                            onChange={(e) => setFavoriteThing(e.target.value)}
                            placeholder={languageOptions[language].placeholders.favoriteThing}
                        />
                    </div>

                    {/* Question 5: Seafood Preference */}
                    <div className="question-block">
                        <label>{languageOptions[language].seafoodPreference}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.seafoodOptions.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={seafoodPreference === option}
                                        onChange={() => setSeafoodPreference(option)}
                                    /> {option}
                                </label>
                            ))}
                            {seafoodPreference === 'Other' && (
                                <input
                                    type="text"
                                    value={seafoodOther}
                                    onChange={(e) => setSeafoodOther(e.target.value)}
                                    placeholder={languageOptions[language].placeholders.favoriteThing}
                                />
                            )}
                        </div>
                    </div>

                    {/* Question 6: Menu Preference */}
                    <div className="question-block">
                        <label>{languageOptions[language].menuPreference}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.menuPreference.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={menuPreference === option}
                                        onChange={() => setMenuPreference(option)}
                                    /> {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Question 7: Customer Service */}
                    <div className="question-block">
                        <label>{languageOptions[language].customerService}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.customerService.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={customerService === option}
                                        onChange={() => setCustomerService(option)}
                                    /> {option}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Question 8: Menu Suggestions */}
                    <div className="question-block">
                        <label>{languageOptions[language].menuSuggestions}</label>
                        <textarea
                            value={menuSuggestions}
                            onChange={(e) => setMenuSuggestions(e.target.value)}
                            placeholder={languageOptions[language].placeholders.menuSuggestions}
                        />
                    </div>

                    {/* Question 9: Dining Time Preference */}
                    <div className="question-block">
                        <label>{languageOptions[language].diningTimePreference}</label>
                        <div className="radio-group">
                            {languageOptions[language].options.diningTimePreference.map(option => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={diningTimePreference === option}
                                        onChange={() => setDiningTimePreference(option)}
                                    /> {option}
                                </label>
                            ))}
                            {diningTimePreference === 'Other' && (
                                <input
                                    type="text"
                                    value={diningTimeOther}
                                    onChange={(e) => setDiningTimeOther(e.target.value)}
                                    placeholder={languageOptions[language].placeholders.favoriteThing}
                                />
                            )}
                        </div>
                    </div>

                    {/* Question 10: Improvement Suggestion */}
                    <div className="question-block">
                        <label>{languageOptions[language].improvementSuggestion}</label>
                        <textarea
                            value={improvementSuggestion}
                            onChange={(e) => setImprovementSuggestion(e.target.value)}
                            placeholder={languageOptions[language].placeholders.improvementSuggestion}
                        />
                    </div>

                    <button type="submit">{languageOptions[language].submit}</button>
                </form>
            )}

            {/* Contact Information */}
            <div className="contact-info">
                <p>
                    Should you have any inquiry, please reach us at{' '}
                    <a href="mailto:support@kingkaewreservation.com">support@kingkaewreservation.com</a>, we will do our best to respond as soon as we can.
                </p>
            </div>
        </div>
    );
};

export default SurveyForm;

