import React, { useState, useEffect } from 'react';
import './Announcement.css'; // Import CSS for styling
import logo from '../asset/kingkaew.png'; // Import the logo from the assets folder

const Announcement = () => {
    const [language, setLanguage] = useState('en');  // Default language set to English
    const [fadeIn, setFadeIn] = useState(false);  // Initially set to false to handle fade-in

    // Trigger fade-in effect when the component mounts (on page load)
    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);  // Trigger the fade-in effect after the component mounts
        }, 100);  // Slight delay to ensure the component renders first
    }, []);  // Empty dependency array to ensure this only runs once on component mount

    // Function to handle language switch with fade effect
    const handleLanguageChange = (lang) => {
        setFadeIn(false);  // Trigger fade-out by disabling the fadeIn state

        // Wait for fade-out to complete before switching language and fading in
        setTimeout(() => {
            setLanguage(lang);  // Change the language
            setFadeIn(true);     // Trigger fade-in effect
        }, 300);  // Match with CSS transition timing (300ms)
    };

    // Announcement content based on selected language
    const announcementText = {
        en: `Kingkaew Seafood would like to thank all our customers who have always supported us and brought us happiness over the years. Due to the economic situation, rising costs have made it necessary for us to gradually adjust our prices, leading to higher operational costs.

Therefore, Kingkaew Seafood Buffet will temporarily close for renovations and improvements to our buffet services starting from 30 September 2024. We sincerely thank you for your understanding and continued support.

We look forward to welcoming you again in our new A La Carte format soon. 🙏🙏`,

        th: `ร้านกิ่งแก้วซีฟู้ด ขอขอบคุณ ลูกค้าทุกๆท่านที่เคยมาอุดหนุนมาแบ่งปันความสุข ตลอดเวลาที่ผ่านมา เนื่องจากภาวะเศรษฐกิจทำให้วัตถุดิบต่างๆมีการปรับราคาสูงขึ้น อย่างต่อเนื่อง ทำให้ต้นทุนในการดำเนินการร้านสูงขึ้น

ทางร้านกิ่งแก้ว ซีฟู้ด ขออนุญาตปิดปรับปรุงการให้บริการบุฟเฟ่ต์อาหารทะเล ตั้งแต่วันที่ 30 กันยายน 2567 ขอบคุณจากใจจากเราคณะผู้บริหาร และพนักงานกิ่งแก้วซีฟู้ดครับ 🙏🙏

(ไว้พบกันในรูปแบบ A La Carte เร็วๆนี้ ครับ)`,

        zh: `Kingkaew 海鲜自助餐衷心感谢各位顾客一直以来的支持与陪伴，这些年来您们带给了我们无数的欢乐。由于当前经济形势，成本上升迫使我们逐步调整价格，导致运营费用增加。

因此，Kingkaew 海鲜自助餐将从2024年9月30日起暂停营业，以进行自助餐服务的改进。我们非常感谢您的理解与支持。

期待不久后在我们的新单点菜单上再次与您相见。🙏🙏`
    };

    return (
        <div className="announcement-container">
            {/* Logo Header */}
            <div className="logo-header">
                <img src={logo} alt="Restaurant Logo" className="logo" /> {/* Use imported logo */}
                <h1>Kingkaew Seafood Announcement</h1>
            </div>

            {/* Language Switcher */}
            <div className="language-switcher">
                <button
                    onClick={() => handleLanguageChange('en')}
                    className={language === 'en' ? 'active' : ''}
                >
                    English
                </button>
                <button
                    onClick={() => handleLanguageChange('th')}
                    className={language === 'th' ? 'active' : ''}
                >
                    ไทย
                </button>
                <button
                    onClick={() => handleLanguageChange('zh')}
                    className={language === 'zh' ? 'active' : ''}
                >
                    中文
                </button>
            </div>

            {/* Announcement Block */}
            <div className={`announcement-block ${fadeIn ? 'fade-in' : ''}`}>
                <p>{announcementText[language]}</p>
            </div>
        </div>
    );
};

export default Announcement;
