import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import necessary components for routing
import CheerWidget from './components/CheerWidget';
import SurveyForm from './components/SurveyForm';
import Announcement from './components/Announcement';
import AdminPanel from './components/AdminPanel'; // Import the AdminPanel

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define the routes */}
        <Routes>
          {/* Main user route */}
          <Route
            path="/"
            element={
              <>
                <Announcement />
                <SurveyForm />
                <CheerWidget />
              </>
            }
          />

          {/* Admin panel route */}
          <Route path="/admin" element={<AdminPanel />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
