import React, { useState, useEffect } from 'react';
import './AdminPanel.css'; // Add some styling

const AdminPanel = () => {
    const [surveyResponses, setSurveyResponses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch survey responses from the backend
        const fetchSurveyResponses = async () => {
            try {
                // Adjust the URL to point to the correct backend route
                const response = await fetch('https://kingkaewreservation.com/api/survey/responses'); // Use full API path
                const data = await response.json();
                setSurveyResponses(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch survey responses');
                setLoading(false);
            }
        };

        fetchSurveyResponses();
    }, []);

    return (
        <div className="admin-panel">
            <h2>Survey Responses</h2>

            {loading ? (
                <p>Loading responses...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Overall Satisfaction</th>
                            <th>Price Satisfaction</th>
                            <th>Seafood Freshness</th>
                            <th>Favorite Thing</th>
                            <th>Seafood Preference</th>
                            <th>Menu Preference</th>
                            <th>Customer Service</th>
                            <th>Suggestions</th>
                            <th>Dining Time Preference</th>
                            <th>Improvement Suggestions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {surveyResponses.map((response, index) => (
                            <tr key={index}>
                                <td>{response.id}</td>
                                <td>{response.overall_satisfaction}</td>
                                <td>{response.price_satisfaction}</td>
                                <td>{response.seafood_freshness}</td>
                                <td>{response.favorite_thing}</td>
                                <td>{response.seafood_preference}</td>
                                <td>{response.menu_preference}</td>
                                <td>{response.customer_service}</td>
                                <td>{response.menu_suggestions}</td>
                                <td>{response.dining_time_preference}</td>
                                <td>{response.improvement_suggestion}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminPanel;
