import React, { useState, useEffect } from 'react';
import { fetchCheerCount, incrementCheerCount } from '../api';
import FloatingCheerIcon from './FloatingCheerIcon';
import './CheerWidget.css'; // Include CSS

const CheerWidget = () => {
    const [cheerCount, setCheerCount] = useState(0);
    const [cheers, setCheers] = useState([]);

    // Utility function to format number with 'k' suffix
    const formatCheerCount = (count) => {
        if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'k'; // Format for thousands with 1 decimal place (e.g., 1.2k)
        }
        return count; // Return the original count if below 1000
    };

    // Fetch the cheer count when the component mounts
    useEffect(() => {
        const getCheerCount = async () => {
            const data = await fetchCheerCount();
            setCheerCount(data.count);
        };

        getCheerCount();
    }, []);

    // Handle cheer button click
    const handleCheerClick = async () => {
        const data = await incrementCheerCount();
        setCheerCount(data.count);

        // Add multiple floating icons with random icons and positions
        const icons = ['🦐', '🦞', '🐟', '🦑', '🦀', '🐠', '🍣']; // Add more icons here
        setCheers((prev) => [
            ...prev,
            { id: Date.now(), icon: icons[Math.floor(Math.random() * icons.length)] },
        ]);
    };

    // Function to remove a cheer icon after animation
    const removeCheer = (id) => {
        setCheers((prev) => prev.filter((cheer) => cheer.id !== id));
    };

    return (
        <div id="cheer-widget">
            <div className="cheer-container">
                {/* Add type="button" to prevent unintended form submissions */}
                <button className="cheer-button" type="button" onClick={handleCheerClick}>
                    🎉 Click to Cheer Us!
                </button>
                <span className="cheer-count">{formatCheerCount(cheerCount)}</span>
            </div>

            <div className="floating-cheers-container">
                {cheers.map((cheer) => (
                    <FloatingCheerIcon
                        key={cheer.id}
                        icon={cheer.icon}
                        remove={() => removeCheer(cheer.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CheerWidget;
