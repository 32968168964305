import React, { useState, useEffect, useRef } from 'react';
import './FloatingCheerIcon.css';

const FloatingCheerIcon = ({ icon, remove }) => {
    const [visible, setVisible] = useState(true);
    const cheerIconRef = useRef(null);

    // Generate random X and Y only once, per icon
    const randomX = useRef(Math.random() * 200 - 100);  // Between -100px to +100px
    const randomY = useRef(Math.random() * -300 - 500); // Between -500px to -800px


    // Run the random animation when the component mounts
    useEffect(() => {
        if (cheerIconRef.current) {
            // Set CSS variables instead of inline styles
            cheerIconRef.current.style.setProperty('--random-x', `${randomX.current}px`);
            cheerIconRef.current.style.setProperty('--random-y', `${randomY.current}px`);
        }

        // Remove the icon after the animation ends
        const timeout = setTimeout(() => {
            setVisible(false);
            remove(); // Call remove to delete the icon from DOM
        }, 4000); // Match with CSS animation duration (4s)

        return () => clearTimeout(timeout);
    }, [remove]);

    if (!visible) return null;

    return (
        <div ref={cheerIconRef} className="floating-cheer">
            {icon}
        </div>
    );

};

export default FloatingCheerIcon;
