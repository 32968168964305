import axios from 'axios';

// Set up the base URL to match your backend server
const API = axios.create({
    baseURL: 'https://kingkaewreservation.com/api/',  // Make sure your backend is running here
});

// Fetch the current cheer count
export const fetchCheerCount = async () => {
    try {
        const response = await API.get('/cheers');
        return response.data;
    } catch (error) {
        console.error('Error fetching cheer count:', error);
        throw error;  // Rethrow error for further handling
    }
};

// Increment the cheer count
export const incrementCheerCount = async () => {
    try {
        const response = await API.post('/cheers');
        return response.data;
    } catch (error) {
        console.error('Error incrementing cheer count:', error);
        throw error;  // Rethrow error for further handling
    }
};

// Submit survey form
export const submitSurvey = async (formData) => {
    try {
        const response = await API.post('/survey', formData);
        return response.data;
    } catch (error) {
        console.error('Error submitting survey:', error);
        throw error;  // Rethrow error for further handling
    }
};
